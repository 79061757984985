.split {
  flex: 1 1 auto;
  display: flex;
  flex-flow: row;

  &.w-60-40 {
    .left {
      flex: 2;
      min-width: 320px;
      max-width: inherit;
    }

    .right {
      flex: 1;
      min-width: 320px;
      max-width: inherit;
    }
  }
}

.left {
  position: relative;
  display: flex;
  flex-flow: column;
  flex: 1 0 auto;
  width: 320px;
  max-width: 320px;
  min-width: 320px;
  border-right: 1px solid #edeef0;

  &.w-380px {
    width: 380px;
    max-width: 380px;
    min-width: 380px;
  }

  @media screen and (max-width: 769px) {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
  }
}

.right {
  display: flex;
  flex-flow: column;
  flex: 1 1 auto;
  border-right: 1px solid #edeef0;
}

.tools {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
  height: 64px;
  min-height: 64px;
  border-bottom: 1px solid #edeef0;
  position: relative;
}

.list {
  padding: 8px;
}

.content {
  padding: 15px;
}

.row-wrapper {
  padding: 2px 4px;
  display: flex;

  &>.row {
    flex: 1 1 auto;
    border: 1px solid #edeef0;
    border-radius: 4px;
    background-color: #f6f7f9;
    color: #313c54;

    h3 {
      margin: 0;
      padding: 10px 10px 5px;
      border-bottom: 1px solid #edeef0;
    }

    h4 {
      font-weight: 100;
      font-size: 11px;
      margin: 0;
      padding: 5px 10px;
    }

    .body {
      padding: 10px;
    }

    .divider {
      position: relative;
      top: 1px;
      margin-right: 5px;
      width: 4px;
      height: 13px;
      border-radius: 2px;
      display: inline-block;

      &.blue1 {
        background-color: #5bb8f1;
      }

      &.blue2 {
        background-color: #5e6cf1;
      }
    }


  }

  &.selected {
    &>.row {
      background-color: #313c54;
      color: #fff;

      h3 {
        border-bottom: 1px solid #435270;
      }
    }
  }
}


