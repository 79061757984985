.expense-editor {
  width: 100%;
  display: flex;
  flex-flow: row;

  .left {
    flex: 2;
    border-right: 1px solid #edeef0;
    position: relative;

    .content {
      position: absolute;
      top: 64px;
      bottom: 0;
      left: 0;
      right: 0;
      overflow: hidden;

      .content-wrapper {
        padding: 20px;
      }
    }
  }

  .right {
    flex: 1;
  }

  .tools {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    height: 64px;
    border-bottom: 1px solid #edeef0;
  }
}

.months {
  position: relative;
  width: 100%;
  display: grid;
  grid-template-columns: auto auto;

  .month{
    width: 141px;
    background-color: #f6f7fa;
    position: relative;
    display: flex;
    flex-direction: column;
    height: 150px;
    margin: 5px;
    border-radius: 4px;
    padding: 5px;

    .header {
      display: block;
      height: 30px;
      min-height: 30px;
      line-height: 30px;
      width: 100%;
      border-radius: 4px;
      background-color: #e5e4e9;

      .title {
        text-align: center;
        margin-top: 0;
      }
    }

    .content {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 80px;
      width: 100%;
      font-size: 21px;
      font-weight: bold;
    }

    .footer {
      display: flex;
      justify-content: space-around;
      align-items: center;
      flex-direction: row;
      height: 50px;
      width: 100%;
      border-radius: 4px;
      background-color: #e5e4e9;

      .bullet {
        display: inline-block;
        height: 5px;
        width: 5px;
        border-radius: 50%;
        margin-right: 5px;
        margin-bottom: 2px;
      }

      .number {
        display: inline-block;
        margin: 0;
        font-size: 11px;
      }

      .approved {
        .bullet {
          background-color: #22d1a2;
        }
      }

      .waiting {
        .bullet {
          background-color: orange;
        }
      }

      .denied {
        .bullet {
          background-color: red;
        }
      }
    }

    &.selected {
      background-color: $dark;
      color: #fff;

      .header,
      .footer {
        background-color: $darker;
        color: #fff;
      }
    }

  }
}

.expenses-list {
  padding: 10px;
}

.expense-item {
  background-color: #f3f4f6;
  border-radius: 4px;

  display: flex;
  flex-flow: column;
  width: 100%;
  margin-bottom: 10px;

  &.business {
    border-left: 10px solid #6f86cc;

    p {
      &.type {
        color: #6f86cc;
      }
    }
  }

  &.travel {
    border-left: 10px solid #68b7eb;

    p {
      &.type {
        color: #6f86cc;
      }
    }
  }

  .details {
    width: 100%;
    display: flex;
    flex-flow: row;
  }

  .info {
    padding: 10px;
    width: 66%;
    flex-flow: row;
  }

  .value {
    background-color: #edeef0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 34%;
    border-left: 1px solid #e7e8ec;
    font-size: 20px;
    font-weight: 500;
  }

  .footer {
    display: flex;
    flex-flow: row;
    border-top: 1px solid #e7e8ec;

    .status {
      width: 66%;
      padding: 10px;
      display: flex;
      align-items: center;
    }

    .actions {
      border-left: 1px solid #e7e8ec;
      padding: 10px;
      width: 34%;
    }
  }

  button {
    padding: 8px 16px;
    font-size: inherit;
    display: inline-block;
    text-align: center;
    font-weight: bold;
    text-decoration: none;
    border: none;
    border-radius: 4px;
    margin-right: 10px;

    &.wire {
      color: #000;
      background-color: transparent;
    }

    &.red {
      color: #fff;
      background-color: #cb2236;
    }

    &.green {
      color: #fff;
      background-color: #38cfa2;
    }

    &.yellow {
      color: #fff;
      background-color: #f8a54d;
    }
  }
}

.year-picker {
  width: 120px;
}

.expenses-popover {
  background-color: #fff;

  .bp3-button {
    width: 100%;
    &.travel {
      background-color: #6db7e8;
      color: #fff;
    }

    &.business {
      background-color: #7087c9;
      color: #fff;
    }
  }
}

.bp3-popover.bp3-popover-content-sizing {
  .bp3-popover-content {
    padding: 10px;
  }
}
