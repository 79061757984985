.submenu {
  list-style: none;
  padding: 0;
  margin: 0;

  li {
    width: 100%;
    display: flex;

    a {
      border-radius: 4px;
      margin: 10px 10px 0;
      width: 100%;
      display: flex;
      background-color: #f6f7f9;
      padding: 15px;
      border: 1px solid #edeef0;
      color: $darker;
    }

    &.active {
      a {
        background-color: $darker;
        border: 1px solid #edeef0;
        color: #fff;
      }
    }
  }

}
