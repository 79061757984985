.dd-area {
  background-color: #edeef0;
  border-radius: 4px;
  margin: 10px;
  padding: 20px;
  text-align: center;
  border: 1px solid #edeef0;
  color: $darker;

  table {
    width: 100%;
    margin: 0;
    padding: 0;
    border-collapse: collapse;
    tr {
      margin: 0;
      padding: 0;
      background-color: #dadada;
      &:nth-of-type(odd) {
        background: #cecece;
      }
    }
    td {
      text-align: left;
      padding: 16px;
      &.r {
        width: 96px;
        display: table-cell;
        text-align: right;
      }
    }
  }
}
