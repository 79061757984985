.double {
    .cols {
        display: flex;
        flex-flow: row;
        .card {
            width: 50%;

            &:first-child {
                margin-right: 5px;
            }

            &:last-child {
                margin-left: 5px;
            }
        }

    }
}
