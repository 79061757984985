.bp3-button {
  position: relative;

  &:not([class*="bp3-intent-"]) {
    background: none;
  }


  &.bp3-intent-success {
    background: #63cea5;
    box-shadow: none;
  }

  &.bp3-intent-danger {
    background: #df3d61;
    box-shadow: none;
  }

  &.has-filters {
    box-shadow: inset 0 0 0 1px #63cea5, inset 0 -1px 0 #63cea5;
  }

  .badge {
    display: flex;
    align-items: center;
    justify-content: center;
    right: -8px;
    top: -8px;
    position: absolute;
    background-color: #22d1a2;
    color: #fff;
    font-size: 11px;
    border-radius: 50%;
    width: 20px;
    height: 20px;

  }

}
