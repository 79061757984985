.ScrollbarsCustom {
  position: relative;
  width: 100%;
  height: 100%;

  .ScrollbarsCustom-Wrapper {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .ScrollbarWrapper {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 13px;
    overflow: hidden;


    .ScrollbarContent {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      overflow: hidden scroll;
      padding-right: 0;
      margin-right: 0;
    }
  }

  .ScrollbarTrack {
    &.ScrollbarTrack-Y {
      position: absolute;
      overflow: hidden;
      border-radius: 4px;
      background: rgba(0, 0, 0, 0.1);
      user-select: none;
      width: 5px;
      height: calc(100%);
      top: 0;
      right: 0;
    }

    &.ScrollbarTrack-X {
      position: absolute;
      overflow: hidden;
      border-radius: 4px;
      background: rgba(0, 0, 0, 0.1);
      user-select: none;
      height: 10px;
      width: calc(100% - 20px);
      bottom: 0px;
      left: 10px;
      display: none;
    }

    .ScrollbarThumb {
      &.ScrollbarThumb-Y {
        touch-action: none;
        cursor: pointer;
        border-radius: 4px;
        background:rgba(0, 0, 0, 0.4);
        width: 100%;
        height: 335.779px;
        transform: translateY(0px);
      }

      &.ScrollbarThumb-X {
        touch-action: none; cursor: pointer; border-radius: 4px; background: rgba(0, 0, 0, 0.4); height: 100%; width: 0px; display: none;
      }
    }
  }
}
