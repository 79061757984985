.ReactVirtualized__Table {
  .ReactVirtualized__Table__headerRow {
    background-color: #344563;
    color: #fff;
    border-radius: 4px 4px 0 0;
    height: 40px;

    .ReactVirtualized__Table__headerColumn {
      position: relative;
      padding: 3px;
      &:not(:last-child) {
        &:after {
          display: block;
          width: 1px;
          height: 30px;
          background-color: #465972;
          content: " ";
          right: 0;
          top: -3px;
          position: absolute;
        }
      }

    }
  }
  .ReactVirtualized__Table__Grid {
    &:focus {
      outline: 0;
    }
    .ReactVirtualized__Table__row {
      cursor: pointer;
      border-left: 1px solid #e5e4ea;
      border-right: 1px solid #e5e4ea;
      border-bottom: 1px solid #e5e4ea;

      &:nth-child(odd) {
        background-color: #fff;
      }
      &:nth-child(even) {
        background-color: #f6f7f9;
      }
      &:hover {
        cursor: pointer;
        background-color: #2d3d59;
        border-left-color: #2d3d59;
        border-right-color: #2d3d59;
        border-bottom-color: #2d3d59;
        color: #fff;

      }
    }
  }
}
