.account {
  display: flex;
  min-height: 460px;
  flex-flow: column-reverse;
  width: 100%;
  height: 100%;
  background-color: #fff;
  box-shadow: 0 2px 50px rgba(0, 0, 0, 0.2);

  .right {
    text-align: left;
  }

  .help-block {
    position: absolute;
  }

  .apps-holder {
    a {
      color: $brand;
      font-weight: 500;
      text-transform: uppercase;
    }
  }

  .left {
    position: relative;
    width: 100%;
    background-color: $darker;
    text-align: center;
    height: 230px;

    .branding {
      margin: 20px auto 0;
    }

    .apps-wrapper {
      background-color: $dark;
      bottom: 0;
      height: 150px;
      padding-top: 25px;
      position: absolute;
      width: 100%;

      .apps-holder {
        padding-top: 15px;

        & > a {
          margin: 5px;
        }
      }

      h2 {
        color: #fff;
        font-size: 16px;
        letter-spacing: .15em;
        opacity: .8;
        text-transform: uppercase;
        margin: 0 0 0.5rem;
      }
    }
  }

  .right {
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    padding: 20px;
    min-height: 400px;

    h3 {
      color: #374560;
      font-size: 20px;
      letter-spacing: -.00933333em;
      margin: 0;
    }

    .form-group {
      margin-bottom: 10px;
      margin-top: 10px;
      padding-top: 15px;
      position: relative;
      background-color: inherit;

      .form-control {
        -webkit-box-shadow: inherit;
        box-shadow: inherit;
      }

      label {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 1;
        transform: translateY(5px);
        color: #aaa;
        font-weight: 300;
        font-size: 13px;
        letter-spacing: -0.00933333em;
        transition: transform 0.2s ease-out;
        margin: 0.1rem 0 0.3rem 0.1rem;
        background-color: inherit;
        width: inherit;
        padding: 0;
      }

      input {
        font-family: inherit;
        appearance: none;
        border: 0;
        border-bottom: 1px solid #aaa;
        font-size: 16px;
        color: #000;
        border-radius: 0;
        padding: 0;

        &[type='text'],
        &[type='email'],
        &[type='password'] {
          width: 100%;
          height: 40px;
        }

        &:focus {
          outline: 0;
        }

        &:placeholder-shown + label {
          opacity: 0;
          transform: translateY(15px);
        }

        &:-webkit-autofill {
          box-shadow: 0 0 0 30px white inset;
        }
      }

      span {
        padding-top: 4px;
        display: block;
        color: red;
        right: 0;
        color: red;
        font-weight: 800;
        font-size: 11px;
      }
    }

    .checkbox-container {
      display: flex;
      margin-top: 50px;

      &>label {
        position: relative;
        color: #aaa;
        font-size: 16px;
        letter-spacing: -0.00933333em;
        font-weight: 300;
        margin-left: 15px;
        box-sizing: content-box;
        transform: translateY(0);
      }

      &>input {
        &[type="checkbox"] {
          cursor: pointer;
          margin: 0;
          height: 22px;
          position: relative;
          width: 22px;
          appearance: textfield;
          transition: opacity 180ms linear;
          border: 0;
          box-sizing: initial;

          &::before {
            border: 1px solid #aaa;
            background-color: #fff;
            content: "";
            width: 20px;
            height: 20px;
            display: block;
            border-radius: 2px;
            transition: opacity 180ms linear;
          }

          &::after {
            content: "";
            border: 2px solid #fff;
            border-right: 0;
            border-top: 0;
            display: block;
            height: 4px;
            left: 4px;
            opacity: 0;
            position: absolute;
            top: 6px;
            transform: rotate(-45deg);
            width: 12px;
            transition: opacity 180ms linear;
          }

          &:checked {
            &::after {
              opacity: 1;
            }

            &::before {
              background-color: $brand;
              border: 1px solid $brand;
            }
          }
        }
      }
    }

    ul {
      &.errors{
        position: absolute;
        padding: 0;
        margin: 0;
        list-style: none;
        color: red;
        font-weight: 800;
      }
    }
  }

  .button-area {
    display: block;
    margin-top: 47px;

    a {
      color: $brand;
      float: right;
      font-size: 14px;
      font-weight: 500;
      padding: 20px 0;
      text-align: right;
      text-transform: uppercase;
    }
  }

  .btn {
    appearance: none;
    background-color: rgba(0,0,0,0);
    border: 0;
    border-radius: 4px;
    cursor: pointer;
    font-family: inherit;
    font-size: 16px;
    height: 50px;
    letter-spacing: -.00933333em;
    padding: 0 35px;
    text-transform: uppercase;
    -webkit-box-shadow: 0 2px 10px rgba(0,0,0,.1);
    background-color: #5db8f1;
    box-shadow: 0 2px 10px rgba(0,0,0,.1);
    color: #fff;
  }

  @media screen and (min-width: 900px) {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-flow: row wrap;
    width: 800px;
    margin: 0 auto;
    height: 440px;
    min-height: 440px;
    position: relative;
    top: 96px;

    .left {
      width: 50%;
      height: inherit;
      position: relative;
      .branding {
        margin: 135px auto 0;
      }
    }

    .right {
      padding: 40px;
      .checkbox-container {
        margin-top: 85px;
      }
    }
  }

  @media screen and (max-width: 380px) {
    form {
      margin-top: -10px;
    }

    .button-area {
      margin-top: 10px;
      a {
        padding: 10px 0;
      }
    }

    .btn {
      padding: 0 25px;
      height: 35px;
    }

    .left {
      position: absolute;
      height: 160px;
      .branding {
        margin: 10px auto 0;
        max-height: 20px;
      }
      .apps-wrapper {
        height: 120px;
        padding-top: 10px;
        .apps-holder {
          padding-top: 5px;
          img {
            max-width: 130px;
          }
        }
      }
    }

    .right {
      .checkbox-container {
        margin-top: 60px;
      }
    }
  }

  @media screen and (max-width: 379px) {
    .left {
      position: absolute;
      height: 130px;

      .apps-wrapper {
        height: 90px;
        padding-top: 10px;
      }
    }
  }

  &.recover-password {
    h3 {
      padding-bottom: 15px;
      font-weight: 500;
    }

    .button-area {
      margin-top: 87px;
    }
  }

  &.logout {
    .right {
      background: url('/images/airport-bg.png') no-repeat right bottom;
    }
  }
}
