.filters {
  background-color: #2d3d59;
  color: #fff;
  .grid {
    display: grid;
    grid-template-columns: 50% 50%;
    min-width: 450px;
    max-width: 480px;
    padding: 15px 15px 8px;
    border-bottom: 1px solid #fff;
  }

  .footer {
    padding: 15px;
  }
}
