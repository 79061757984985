.slide-panel {
  background-color: red;
  display: flex;
  flex-direction: column;
  background: #fff;
  min-width: 100px;
  height: 100%;
  box-shadow: 0 8px 8px rgba(0,0,0,0.5);
  transition: transform .5s;
  will-change: transform;

  &:focus{
    outline-style: none;
  }

  &_from_right{
    margin-left: auto;
    transform: translateX(100%);
    &.ReactModal__Content--after-open{
      transform: translateX(0%)
    }
    &.ReactModal__Content--before-close{
      transform: translateX(100%);
    }
  }
  &_from_left{
    margin-right: auto;
    transform: translateX(-100%);
    &.ReactModal__Content--after-open{
      transform: translateX(0%);
    }
    &.ReactModal__Content--before-close{
      transform: translateX(-100%);
    }
  }
  &__overlay{
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: rgba(0, 0, 0, 0);
  }
  &__overlay.ReactModal__Overlay--after-open{
    background-color: rgba(0, 0, 0, .3);
    transition: background-color .5s;
    z-index: 1050;
  }
  &__overlay.ReactModal__Overlay--before-close{
    background-color: rgba(0, 0, 0, 0);
  }
  &__header{
    display: flex;
    flex: 0 0 64px;
    align-items: center;
    background: #ebebeb;
    height: 64px;
    border-bottom: 1px solid rgba(0, 0, 0, .1);
    color: #fff;
    background-color: #2f3c55;
  }
  &__title-wrapper{
    display: flex;
    flex: 1;
    flex-direction: column;
    min-width: 0;
    margin-left: 0;
  }
  & &__title{
    font-size: 16px;
    font-weight: 300;
    max-width: 80%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
    padding: 0;
  }
  &__close{
    margin-left: 16px;
    padding: 16px;
    opacity: .7;
    cursor: pointer;

    svg{
      width: 12px;
      padding: 0;
    }
  }
  &__content{
    position: relative;
    overflow-y: auto;
    flex: 1 1 auto;
    height: 100%;

    .data {
      padding-bottom: 0;
    }

    form {
      padding: 20px;
    }
  }
  &__subtitle{
    font-size: 12px;
    margin-top: 2px;
  }
  .slide-panel__title {
    color: inherit;

    svg {
      width: 16px;
      height: 16px;
      fill: #fff;
    }
  }
}
