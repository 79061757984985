.panel {
  display: flex;
  flex: 1 1 auto;
  flex-flow: column;
  height: 100%;

  .panel-head {
    position: relative;
    background-color: #374561;
    border-radius: 4px 4px 0 0;
    color: #fff;
    padding: 20px;
    text-align: center;
    justify-content: center;

    .tools {
      border: none;
      display: flex;
      justify-content: flex-end;
      min-height: inherit;
      height: inherit;
      padding: 0;

      .icon {
        margin-left: 32px;
        cursor: pointer;
      }
    }
  }

  .panel-body {
    background-color: #eceff0;
    flex: 1 1 auto;
    padding: 20px;
  }

  .data-item-container {
    display: table;
    border-radius: 4px;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.22);
    border-image: initial;
    margin: 0 auto 4px;

    &>div {
      display: table-cell;
      padding: 5px 15px;
    }

    &.blue {
      background-color: #263044;
      &>div {
        background-color: rgba(0, 0, 0, 0.1);

        &:first-child {
          background-color: rgba(0, 0, 0, 0.3);
        }
      }
    }

    &.white {
      width: 100%;
      background-color: #fff;

      &>:first-child {
        width: 60%;
      }

      &>:last-child {
        width: 40%;
      }
    }
  }
}

