.root, .app, .authenticated {
  display: flex;
  flex-flow: column;
  position: relative;
  overflow: hidden;
  height: 100%;
  min-height: 100%;
}

main {
  position: relative;
  display: flex;
  flex-flow: row;
  flex: 1 1 auto;
  overflow: hidden;
}

.module {
  display: flex;
  flex-flow: row;
  flex: 1 1 auto;
}

.flex {
  display: flex;

  &.column {
    flex-flow: column;
  }

  &.row {
    flex-flow: row;
  }
}

.absolute {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.relative {
  position: relative;
  height: 100%;
}

.stretch {
  flex: 1 1 auto;
  overflow: hidden;
  position: relative;
}

.center {
  text-align: center;
}

.bp3-portal {
  z-index: 1051;
}

.bp3-popover-target {
  width: 100%;
}
