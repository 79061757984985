.rc-tabs {
  box-sizing: border-box;
  position: relative;
  overflow: hidden;

  &-bar, &-nav-container {
    font-size: 14px;
    line-height: 1.5;
    box-sizing: border-box;
    overflow: hidden;
    position: relative;
    white-space: nowrap;
    outline: none;
    zoom: 1;
    transition: padding .45s;
  }

  &-ink-bar {
    z-index: 1;
    position: absolute;
    box-sizing: border-box;
    margin-top: -3px;
    background-color: #68b8ec;
    transform-origin: 0 0;

    width: 0;
    height: 0;

    &-animated {
      transition: transform .3s cubic-bezier(0.35, 0, 0.25, 1),
      left .3s cubic-bezier(0.35, 0, 0.25, 1),
      top .3s cubic-bezier(0.35, 0, 0.25, 1),
      height .3s cubic-bezier(0.35, 0, 0.25, 1),
      width .3s cubic-bezier(0.35, 0, 0.25, 1);
    }
  }

  &-tab-prev, &-tab-next {
    user-select: none;
    z-index: 1;
    line-height: 48px;
    cursor: pointer;
    border: none;
    background-color: transparent;
    position: absolute;

    &-icon {
      position: relative;
      display: inline-block;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      line-height: inherit;
      vertical-align: baseline;
      text-align: center;
      text-transform: none;
      font-smoothing: antialiased;
      text-stroke-width: 0;
      font-family: sans-serif;

      &:before {
        display: block;
      }
    }
  }

  &-tab-btn-disabled {
    cursor: default;
    color: #ccc;
  }

  &-nav-wrap {
    overflow: hidden;
  }

  &-nav {
    box-sizing: border-box;
    padding-left: 0;
    position: relative;
    margin: 0;
    float: left;
    list-style: none;
    display: inline-block;
    transform-origin: 0 0;

    &-animated {
      transition: transform 0.5s cubic-bezier(0.35, 0, 0.25, 1);
    }

    &:before, &:after {
      display: table;
      content: " ";
    }

    &:after {
      clear: both;
    }
  }

  &-tab {
    box-sizing: border-box;
    position: relative;
    display: block;
    transition: color .3s cubic-bezier(0.35, 0, 0.25, 1);
    padding: 12px 16px;
    font-weight: 500;
    cursor: pointer;
    border: 1px solid #edeef0;
    border-bottom-width: 7px;
    color: $darker;


    &:hover {
      color: #23c0fa;
    }
  }

  &-tab-active {
    &, &:hover {
      color: #68b8ec;
      background-color: rgb(235, 239, 240);
      cursor: default;
      // fix chrome render
      transform: translateZ(0);
    }
  }

  &-tab-disabled {
    cursor: default;
    color: #ccc;

    &:hover {
      color: #ccc;
    }
  }

  &-content {
    zoom: 1;

    .rc-tabs-tabpane {
      overflow: auto;
    }

    &-animated {
      transition: transform .3s cubic-bezier(0.35, 0, 0.25, 1),
      margin-left .3s cubic-bezier(0.35, 0, 0.25, 1),
      margin-top .3s cubic-bezier(0.35, 0, 0.25, 1);
      display: flex;
      will-change: transform;

      .rc-tabs-tabpane {
        flex-shrink: 0;
      }
    }
  }

  .no-flexbox &-content {
    transform: none !important;
    overflow: auto;
  }

  .no-csstransitions &-tabpane-inactive,
  .no-flexbox &-tabpane-inactive,
  &-content-no-animated &-tabpane-inactive {
    display: none;
  }


  &-left {
    border-right: 2px solid #f3f3f3;
  }

  &-left &-bar {
    float: left;
    height: 100%;
    margin-right: 10px;
    border-right: 1px solid #f3f3f3;
  }

  &-left &-nav-container {
    height: 100%;
  }

  &-left &-nav-container-scrolling {
    padding-top: 32px;
    padding-bottom: 32px;
  }

  &-left &-nav-wrap {
    height: 100%;
  }

  &-left &-content-animated {
    flex-direction: column;

    .rc-tabs-tabpane {
      height: 100%;
    }
  }

  &-left &-nav-scroll {
    height: 99999px;
  }

  &-left &-nav-swipe {
    position: relative;
    top: 0;

    .rc-tabs-nav {
      display: flex;
      flex: 1;
      flex-direction: column;
      height: 100%;

      .rc-tabs-tab {
        display: flex;
        flex-shrink: 0;
        justify-content: center;
      }
    }
  }

  &-left &-tab-prev, &-left &-tab-next {
    margin-top: -2px;
    height: 0;
    line-height: 32px;
    width: 0;
    display: block;
    text-align: center;
    opacity: 0;
    transition: width .3s, height .3s, opacity .3s;
  }

  &-top &-tab-arrow-show,
  &-left &-tab-arrow-show,
  &-bottom &-tab-arrow-show,
  &-right &-tab-arrow-show {
    opacity: 1;
    width: 100%;
    height: 32px;
  }

  &-left &-tab-next {
    bottom: 0;

    &-icon {
      transform: rotate(90deg);
      filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1);
    }

    &-icon:before {
      content: ">";
    }
  }

  &-left &-tab-prev {
    top: 2px;

    &-icon {
      transform: rotate(270deg);
      filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
    }

    &-icon:before {
      content: ">";
    }
  }

  &-left &-ink-bar {
    width: 2px;
    right: 0;
    top: 0;
  }

  &-left &-tab {
    padding: 16px 24px;
  }

  &-right {
    border-left: 2px solid #f3f3f3;
  }

  &-right &-bar {
    float: right;
    height: 100%;
    margin-left: 10px;
    border-left: 1px solid #f3f3f3;
  }

  &-right &-nav-container {
    height: 100%;
  }

  &-right &-nav-container-scrolling {
    padding-top: 32px;
    padding-bottom: 32px;
  }

  &-right &-nav-wrap {
    height: 100%;
  }

  &-right &-nav-scroll {
    height: 99999px;
  }

  &-right &-nav-swipe {
    position: relative;

    .rc-tabs-nav {
      display: flex;
      flex: 1;
      flex-direction: column;
      height: 100%;

      .rc-tabs-tab {
        display: flex;
        flex-shrink: 0;
        justify-content: center;
      }
    }
  }

  &-right &-tab-prev, &-right &-tab-next {
    margin-top: -2px;
    height: 0;
    width: 0;
    display: block;
    text-align: center;
    line-height: 32px;
    opacity: 0;
    transition: width .3s, height .3s, opacity .3s;
  }


  &-top &-tab-arrow-show {
    opacity: 1;
    width: 100%;
    height: 32px;
  }

  &-right &-tab-next {
    bottom: 0;

    &-icon {
      transform: rotate(90deg);
      filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1);
    }

    &-icon:before {
      content: ">";
    }
  }

  &-right &-tab-prev {
    top: 2px;

    &-icon {
      transform: rotate(270deg);
      filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
    }

    &-icon:before {
      content: ">";
    }
  }

  &-right &-content-animated {
    flex-direction: column;

    .rc-tabs-tabpane {
      height: 100%;
    }
  }

  &-right &-ink-bar {
    width: 2px;
    left: 0;
    top: 0;
  }

  &-right &-tab {
    padding: 16px 24px;
  }

  &-bottom {
    border-top: 2px solid #f3f3f3;
  }

  &-bottom &-content {
    width: 100%;
  }

  &-bottom &-bar {
    border-top: 1px solid #f3f3f3;
  }

  &-bottom &-nav-container-scrolling {
    padding-left: 32px;
    padding-right: 32px;
  }

  &-bottom &-nav-scroll {
    width: 99999px;
  }

  &-bottom &-nav-swipe {
    position: relative;
    left: 0;

    .rc-tabs-nav {
      display: flex;
      flex: 1;
      width: 100%;

      .rc-tabs-tab {
        display: flex;
        flex-shrink: 0;
        margin-right: 0;
        padding: 8px 0;
        justify-content: center;
      }
    }
  }

  &-bottom &-nav-wrap {
    width: 100%;
  }

  &-bottom &-content-animated {
    flex-direction: row;

    .rc-tabs-tabpane {
      width: 100%;
    }
  }

  &-bottom &-tab-next {
    right: 2px;

    &-icon:before {
      content: ">";
    }
  }

  &-bottom &-tab-prev {
    left: 0;

    &-icon:before {
      content: "<";
    }
  }

  &-bottom &-tab-prev, &-bottom &-tab-next {
    margin-right: -2px;
    width: 32px;
    height: 100%;
    top: 0;
    text-align: center;
  }

  &-bottom &-ink-bar {
    height: 7px;
    top: 3px;
    left: 0;
  }

  &-bottom &-tab {
    float: left;
    height: 100%;
    margin-right: 16px;
  }

  &-bottom &-tabpane-inactive {
    height: 0;
    overflow: visible;
  }

  &-top &-content {
    width: 100%;
  }

  &-top &-nav-container-scrolling {
    padding-left: 32px;
    padding-right: 32px;
  }

  &-top &-nav-scroll {
    width: 99999px;
  }

  &-top &-nav-swipe {
    position: relative;
    left: 0;

    .rc-tabs-nav {
      display: flex;
      flex: 1;
      width: 100%;

      .rc-tabs-tab {
        display: flex;
        flex-shrink: 0;
        margin-right: 0;
        padding: 8px 0;
        justify-content: center;
      }
    }
  }

  &-top &-nav-wrap {
    width: 100%;
  }

  &-top &-content-animated {
    flex-direction: row;

    .rc-tabs-tabpane {
      width: 100%;
    }
  }

  &-top &-tab-next {
    right: 2px;

    &-icon:before {
      content: ">";
    }
  }

  &-top &-tab-prev {
    left: 0;

    &-icon:before {
      content: "<";
    }
  }

  &-top &-tab-prev, &-top &-tab-next {
    margin-right: -2px;
    width: 0;
    height: 0;
    top: 0;
    text-align: center;
    opacity: 0;
    transition: width .3s, height .3s, opacity .3s;
  }

  &-top &-tab-arrow-show {
    opacity: 1;
    width: 27px;
    height: 100%;
  }

  &-top &-ink-bar {
    height: 7px;
    bottom: 0;
    left: 0;
  }

  &-top &-tab {
    float: left;
    height: 100%;
  }

  &-top &-tabpane-inactive {
    height: 0;
    overflow: visible;
  }

}
